import React, { useState } from "react";

const Form = (props) => {
    // const [contact, setContact] = useState({
    //     fullname: "",
    //     email: "",
    //     message: ""
    // });

    // function handleFormChanges(event) {
    //     const { value, name } = event.target;
    //     setContact(prevValue => {
    //         return {
    //             ...prevValue,
    //             [name]: value
    //         }
    //     });
    // }

    // function handleSubmit(event){
    //     window.open("mailto:c.trotman92@gmail.com?subject=Customer%20Enquiry&body="+contact.message);
    //     event.preventDefault();
    // }

    return (
        // <div className="contact-card">
        //     <form action="https://send.pageclip.co/E3XGNxyvpXaW8eovvBFLD6IfNEcUZSEa" class="pageclip-form" method="post">

        //         <input type="text" name="name" value="Roscoe Jones" />
        //         <input type="email" name="email" value="roscoe@example.com" />
        //         <input type="text" name="message" value="Enter Message" />

        //         <button type="submit" class="button pageclip-form__submit">
        //             <span>Send</span>
        //         </button>
        //     </form>
        // </div>
        <div className="contact-card">
            <form action="https://send.pageclip.co/E3XGNxyvpXaW8eovvBFLD6IfNEcUZSEa" class="pageclip-form contact-form" method="post">
                <input type="text" name="subject" value="Customer Enquiry"/>
                <input type="text" placeholder="Full name" name="fullname" />
                <input type="text" placeholder="Email" name="email" />
                <input type="text" placeholder="Contact Number" name="contact" />
                <input type="date" placeholder="Moving Date" name="moveDate" />
                <textarea name="oldAdd" placeholder="Old Address" rows={5} cols={50} />
                <textarea name="newAdd" placeholder="New Address" rows={5} cols={50} />
                <input type="text" placeholder="Bed amount" name="bedCount" />
                <div className="row">
                    <label className="w-50">Any garden content?</label>
                    <input className="w-50" type="checkbox" placeholder="contentGarage" name="contentGarage" />
                </div>
                <div className="row">
                    <label className="w-50">Any garden content?</label>
                    <input className="w-50" type="checkbox" placeholder="contentGarden" name="contentGarden" />
                </div>
                <div className="row">
                    <label className="w-50">Do you require a packing service?</label>
                    <input className="w-50" type="checkbox" placeholder="servicePacking" name="servicePacking" />
                </div>
                <div className="row">
                    <label className="w-50">Do you require a dismantle service?</label>
                    <input className="w-50" type="checkbox" placeholder="serviceDismantle" name="serviceDismantle" />
                </div>
                <input type="text" placeholder="Special Attention Items" name="specialAttention" />
                <input type="text" placeholder="Storage length" name="storageCount" />

                <textarea name="message" placeholder="Enter message here" rows={5} cols={50} />
                <button type="submit" class="button pageclip-form__submit">
                    <span>Send</span>
                </button>
            </form>
        </div>
    );
}

export default Form;